@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');
@layer base{
  li{
    @apply p-4
  }
}

html{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background-color: #ffffff;

}

span{
  text-transform: uppercase;
  font-weight: 600;
  font-size: 4rem;
  padding: 1rem;
  z-index: 9999;
  
  font-family: 'Koulen', cursive;}

  .content-103{
    background: #f3f3f3;
    padding-bottom:50px;
    justify-content: center;
  }
  
  .content-103 .sercies-title{
    text-align:center;
    padding: 50px 0;
  }
  
  .service-post {
    background: #fff;
    padding: 30px;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 12;
    margin: 30px;
    -moz-box-shadow: 0 5px 4px -4px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 5px 4px -4px rgba(0, 0, 0, 0.08);
    box-shadow: 0 5px 4px -4px rgba(0, 0, 0, 0.08);
    
  }
  .col-sm-4 {
    flex: 0 0 auto;
  }
  @media screen and (max-width:768px) { 
    .col-sm-4{
      width: 100%;
    }
    .col-md-4{
      width: 100%;
    }
  }
  .content-103 .service-content {
    position: relative;
    z-index: 13;
  }
  .content-103 .service-icon {
    margin: 10px 0 20px 0;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    border: 2px solid rgb( 85, 214, 190);
    margin-left: auto;
    margin-right: auto;
    line-height: 50px;
    -moz-transition: color, 250ms;
    -o-transition: color, 250ms;
    -webkit-transition: color, 250ms;
    transition: color, 250ms;
  }
  .content-103 .service-post:hover .service-icon {
    border-color: #fff;
  }
  .content-103 .service-icon i {
    font-size: 18px;
    color: rgb( 85, 214, 190);
  }
  .content-103 .service-post:hover .service-icon i {
    color: #fff;
  }
  .content-103 .service-title {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 15px;
    -moz-transition: color, 250ms;
    -o-transition: color, 250ms;
    -webkit-transition: color, 250ms;
    transition: color, 250ms;
  }
  .content-103 .service-post:hover .service-title {
    color: #fff;
  }
  .content-103 .service-description {
    -moz-transition: color, 250ms;
    -o-transition: color, 250ms;
    -webkit-transition: color, 250ms;
    transition: color, 250ms;
  }
  .content-103 .service-post:hover .service-description {
    color: #fff;
  }
  
  
  .content-103 .service-hover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 0px;
    background: #86EFAC;
    -moz-transition: width, 250ms;
    -o-transition: width, 250ms;
    -webkit-transition: width, 250ms;
    transition: width, 250ms;
  }
  /*hover-background-color effect*/
  .content-103 .service-post:hover .service-hover {
    height: 100%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
    z-index: 1;
  }
  .rowC{
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    height: -10rem;
  
  }
  @media screen and (max-width:768px) { 
    .col-sm-4{
      width: 100%;
    }
    .col-md-4{
      width: 100%;
    }
  }
  .t-title{
    text-align: center;
    padding: 50px 0px;
    margin-left: auto;
  
  }
  /* testimonials */
  .carousel-root {
    width: 64% !important;
    margin: auto !important;
    margin-top: 3% !important;
  }
  
  .carousel .slide {
    background: #fff !important;
    color: black;
    height: 100%;
  }
  
  .carousel .slide img {
    width: 139px !important;
    border-radius: 50%;
  }
  
  .myCarousel {
    background: #fafafa;
    margin-top: -6%;
    width: 54%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 286px;
  }
  
  .carousel .control-dots {
    padding-left: 5px !important;
    outline: 0;
    bottom: 5% !important;
  }
  
  .myCarousel h3 {
    color: #222;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
  }
  
  .myCarousel h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #787878;
    font-size: 14px;
  }
  
  .myCarousel p {
    font-weight: 100 !important;
    line-height: 29px !important;
    color: #222;
    font-size: 15px;
    font-family: sans-serif;
    max-height: 67px;
  }
  
  .myCarousel p:before {
    content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
  }
  
  .myCarousel p:after {
    content: "”";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
  }
  
  .carousel .control-dots .dot {
    box-shadow: none !important;
    background: #454545 !important;
    outline: 0;
  }
  
  .carousel.carousel-slider .control-arrow {
    background: #000 !important;
    height: 50px !important;
    position: absolute;
    top: 35% !important;
  }
  
  .App {
    text-align: center;
  }
  
  @media only screen and (max-width: 934px) {
    .carousel-root {
      outline: 0;
      width: 93% !important;
      margin: auto !important;
    }
  
    .carousel.carousel-slider .control-arrow {
      display: none !important;
    }
    .myCarousel {
      background: #fafafa;
      margin-top: -9%;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 8%;
      padding-bottom: 12.5%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
      height: 269px;
    }
  
    .carousel .slide img {
      width: 24% !important;
      border-radius: 50%;
    }
  }
  
  
  form {
    max-width: 70vw;
    text-align: center;
    margin: 20px auto;
    z-index: -1;
  }
  form input, form textarea {
    border: 0;
    outline: 0;
    padding: 1em;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 1em;
    font-family: 'Merriweather', sans-serif;
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    resize: none;
  }
  form input:focus, form textarea:focus {
    -moz-box-shadow: 0 0px 2px #333 !important;
    -webkit-box-shadow: 0 0px 2px #333 !important;
    box-shadow: 0 0px 2px #333 !important;
  }
  form #input-submit {
    color: white;
    /* background: #333; */
    cursor: pointer;
  }
  form #input-submit:hover {
    -moz-box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
    -webkit-box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
    box-shadow: 0 1px 1px 1px rgba(170, 170, 170, 0.6);
  }
  form textarea {
    height: 126px;
  }
  
  .half {
    float: left;
    width: 48%;
    margin-bottom: 1em;
  }
  
  .right {
    width: 50%;
  }
  
  .left {
    margin-right: 2%;
  }
  
  @media (max-width: 480px) {
    .half {
      width: 100%;
      float: none;
      margin-bottom: 0;
    }
  }
  /* Clearfix */
  .cf:before,
  .cf:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
  }
  
  .cf:after {
    clear: both;
  }
  
  /* Contact Us */
  
li{
  cursor: pointer !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background-color: #16A34A !important;
}
.carousel.carousel-slider .control-arrow{
  color: #16A34A !important;
}